.buyBG {
	width: 100%;
	height: 100%;
	background-color: #212121;
}
.buyDiv {
	border: solid 2px#BF9F5B;
	background-color: #353533;
	margin: 18px;
	margin-top: 0;
	padding: 16px 18px;
	border-radius: 6px;
	box-shadow: 0px 5px 10px rgb(0 0 0 / 30%);
}
.titleTextSub {
	text-align: center;
	color: #e9ad00;
	font-size: 18px;
	font-weight: bold;
}
.titleText {
	text-align: center;
	color: #e9ad00;
	font-size: 18px;
	font-weight: bold;
}
div.titleText > p {
	font-size: 13px;
	color: #f8e6cb;
	font-weight: lighter;
	margin-top: 4px;
	line-height: 1.6;
}
div.titleText > p > span {
	font-weight: bold;
}
.inputDiv {
	display: flex;
	width: 100%;
	border: solid 1px#BF9F5B;
	font-size: 15px;
	background-color: #f4f3ee;
	border-radius: 4px;
	margin-bottom: 10px;
	color: #212121;
	align-items: center;
}
div.inputDiv > label {
	width: 100%;
	margin: 3px 0 3px 10px;
}
.productDiv {
	position: relative;
	background-color: #f4f3ee;
	width: 100%;
	border: solid 1px #bf9f5b;
	margin-bottom: 10px;
	font-size: 15px;
	border-radius: 4px;
}
div.productDiv > label {
	display: grid;
	padding: 10px;
}
div.productDiv > label > span {
	position: absolute;
	right: 12px;
}
.productInput {
	display: none;
}
.productInputOpen {
	display: grid;
}
div.productDiv > label > textarea {
	font-weight: bold;
	padding: 4px 0 12px 0;
	border: none;
	background: none;
	color: #811414;
	font-size: 15px;
	text-align: justify;
	text-justify: auto;
	resize: none;
	overflow: hidden;
}
div.inputDiv > label > input {
	font-weight: bold;
	height: 36px;
	font-size: 15px;
	border: none;
	background-color: #f4f3ee;
	outline: #000;
	box-sizing: border-box;
	word-break: break-all;
}
div.inputDiv > span {
	display: flex;
	padding-right: 12px;
}
::placeholder {
	color: #5c5c5c;
}

.arrowBuy {
	width: 20px;
	height: 20px;
	transition: ease 0.2s;
}
.addressDiv {
	background-color: #e7e7e7;
	width: 100%;
	border: solid 1px #bf9f5b;
	margin-bottom: 10px;
	font-size: 15px;
	border-radius: 4px;
}
div.addressDiv > label {
	display: grid;
	padding: 10px;
}
div.addressDiv > label > textarea {
	padding: 4px 4px 8px 4px;
	border: none;
	font-size: 15px;
	text-align: justify;
	text-justify: auto;
	resize: none;
	overflow: hidden;
	margin-top: 4px;
}
.addBlessUse {
	width: 100%;
	font-size: 16px;
	font-weight: bold;
	color: #fff;
	background: none;
	border: none;
	margin: 18px 0 8px 0;
}
button.addBlessUse > span {
	border-radius: 100px;
	padding: 2px;
	background-color: #811414;
}
.modalClose {
	width: 0%;
	position: fixed;
	bottom: 0;
	left: 0;
	background-color: #f3ecec;
	overflow-x: hidden;
	display: flex;
	flex-direction: column;
	z-index: 910;
}
.modalOpen {
	width: 100%;
	z-index: 1000;
	border-radius: 4px;
}
.modalOpacityOpen {
	top: 0;
	left: 0;
	position: fixed;
	background-color: #000;
	opacity: 0.6;
	width: 100%;
	height: 100%;
	transition: 0.2s ease;
	z-index: 999;
}
.modalDecUp {
	margin: 4px;
}
span.decRight > svg {
	transform: scaleX(-1);
	float: right;
}
.modalDecDown {
	margin: 4px;
}
span.decLeftDown > svg {
	transform: scaleY(-1);
}
span.decRightDown > svg {
	transform: rotate(180deg);
	float: right;
}
.product {
	font-weight: bold;
	height: 50px;
	padding: 0 24px;
	display: flex;
	align-items: center;
}

.productSub {
	color: #212121;
	font-weight: normal;
	padding-left: 5px;
}

.productCost {
	line-height: 1.3;
	padding-right: 34px;
	font-weight: normal;
	color: #a33131;
}

span.productCost > span {
	font-size: 12px;
}
div.product > div {
	position: absolute;
	right: 16px;
}

.productLine {
	border: solid 1px #dfd4bd;
	margin: 0 24px;
}
.modalFinish {
	padding: 8px 60px;
	border-radius: 28px;
	border: solid 1px #9f1430;
	margin: 20px auto;
	background-color: #a33131;
	color: #fff;
	font-weight: bold;
	font-size: 16px;
	box-shadow: 0px 2px 6px rgb(0 0 0 / 50%);
	text-align: center;
	margin-bottom: 0;
}

/* 付款方式 */

label.container > .checkmark {
	right: 12px;
}

div.paySelect > .container {
	position: relative;
	background-color: #f4f3ee;
	padding: 8px 0 9px 10px;
	font-size: 15px;
	color: #212121;
	border-radius: 4px;
	margin-bottom: 10px;
	border: solid 1px #bf9f5b;
}

.payPhoneOpen {
	/* display: flex; */
	width: 100%;
	font-size: 15px;
	background-color: #f4f3ee;
	border-radius: 4px;
	margin-bottom: 10px;
	color: #212121;
	transition: ease 0.2s;
	overflow: hidden;
	margin-top: -8px;
	/* align-items: center; */
}
div.payPhoneOpen > label {
	width: 100%;
	margin: 3px 0 3px 10px;
}

div.payPhoneOpen > label > input {
	width: 75%;
	height: 36px;
	font-size: 15px;
	border: none;
	background-color: #f4f3ee;
	outline: #000;
	box-sizing: border-box;
	word-break: break-all;
}
.container {
	display: flex;
	align-items: center;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	font-weight: normal;
}

/* Hide the browser's default checkbox */
.container input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}

/* Create a custom checkbox */
.checkmark {
	position: absolute;
	right: 6px;
	height: 20px;
	width: 20px;
	background-color: #eee;
	border: solid 1px #a9a9a9;
	border-radius: 4px;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
	background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
	background-color: #9f1430;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
	content: '';
	position: absolute;
	display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
	display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
	left: 6px;
	top: 2px;
	width: 5px;
	height: 10px;
	border: solid white;
	border-width: 0 3px 3px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}

/* 刪除ＭＯＤＡＬ */

.delModalClose {
	width: 0%;
	position: fixed;
	bottom: 0;
	left: 0;
	background-color: #f3ecec;
	overflow-x: hidden;
	display: flex;
	flex-direction: column;
	z-index: 910;
}
.delModalOpen {
	position: fixed;
	text-align: center;
	width: 86%;
	left: 7%;
	top: 0;
	bottom: 0;
	margin: auto;
	height: 30%;
	border-radius: 8px;
	background-color: #fff;
	z-index: 1000;
}
div.delModalOpen > div {
	margin: auto;
	font-size: 15px;
}
div.delModalOpen > div > h4 {
	margin: 0;
	margin-bottom: 36px;
}
.delYes {
	padding: 8px 36px;
	font-size: 15px;
	font-weight: bold;
	color: #fff;
	margin-right: 12px;
	background-color: #5c5c5c;
	border-radius: 100px;
	border: none;
}
.delNo {
	padding: 8px 36px;
	font-size: 15px;
	font-weight: bold;
	color: #fff;
	background-color: #a33131;
	border-radius: 100px;
	border: none;
}

.tipDel {
	line-height: 22px;
	font-size: 13px;
	color: #fff;
	/* text-align: end; */
	margin-right: 18px;
}
.delOrder {
	display: inline-flex;
	position: absolute;
	right: 34px;
	fill: #9f1430;
}
.closeIcon {
	width: 23px;
	height: 23px;
}
.totalDiv {
	height: 48px;
	margin: 18px;
	display: flex;
	margin-top: 26px;
}
div.totalDiv > label {
	font-size: 13px;
	background-color: #ffffff;
	width: 60%;
	height: 100%;
	padding-left: 18px;
	align-items: center;
	display: flex;
	border-radius: 6px 0 0 6px;
}
.totalCost {
	font-size: 17px;
	color: #9f1430;
	height: 100%;
	width: 60%;
	border: none;
	background: none;
	outline: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.submitDiv {
	height: 100%;
	background-color: #9f1430;
	width: 40%;
	text-align: center;
	border-radius: 0 6px 6px 0;
}
.submitBtn {
	font-size: 15px;
	font-weight: bold;
	color: #fff;
	width: 100%;
	border: none;
	height: 100%;
	background: none;
}

.inputNullModalDiv {
	display: flex;
	justify-content: center;
}
.inputNullModal {
	position: fixed;
	top: 50px;
	width: 90%;
	font-size: 14px;
	background-color: #fdd703;
	border-radius: 6px;
	text-align: center;
	text-justify: auto;
	box-shadow: 0px 2px 6px rgb(0 0 0 / 30%);
}
.inputNullIcon {
	position: absolute;
	left: 12px;
	fill: #9f1430;
	width: 20px;
	height: 20px;
}

.buyPageImg {
	width: 100%;
}
