.playBtnDiv {
	display: none;
	margin-top: 12px;
}
.playBtn {
	justify-content: center;
	width: 100%;
	height: 52vh;
	position: absolute;
	display: flex;
	z-index: 1;
	align-items: center;
}

.shareContent {
	position: relative;
	padding: 18px 24px;
	/* margin-top: -38px; */
	font-size: 16px;
	text-align: justify;
	text-justify: auto;
	line-height: 1.7;
	background-color: #fff;
}
div.shareContent > span {
	font-size: 18px;
	font-weight: bold;
	color: darkred;
}

.playerDiv {
	position: relative;
	width: 100%;
	height: 0;
	padding-bottom: 100%;
	background-color: #131415;
	background-image: url(../../../images/listBGrepeat.png);
	color: darkgoldenrod;
	font-size: 16px;
	text-align: center;
}
.loadText {
	display: flex;
	height: 55vh;
	align-items: center;
	justify-content: center;
}

.react-player {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.lineShare {
	display: flex;
	justify-content: center;
}
div.lineShare > button {
	position: fixed;
	width: 90%;
	max-width: 600px;
	bottom: 36px;
	color: #fff;
	font-weight: bold;
	display: flex;
	align-items: center;
	background-color: #04c755;
	border-radius: 12px;
	font-size: 18px;
	padding: 8px 16px;
	border: none;
	justify-content: center;
	box-shadow: 0px 2px 6px rgb(0 0 0 / 30%);
}

div.lineShare > button > img {
	height: 30px;
	margin-right: 6px;
}
