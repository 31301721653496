#root {
	/* background-color: #fff; */
	max-width: 720px;
	margin: auto;
	background-color: transparent;
	
}
/* .mainBody {
	background-color: #212121;
}



.ulspace {
	padding: 0 18px 0 18px;
}

ul {
	list-style-type: none;
	margin: 0;
	padding: 0;
	margin-bottom: 28px;
	font-size: 20px;
	font-weight: bold;
}
.buddhaName li {
	background-color: #877420;
}
.buddhaScriptures li {
	background-color: #23283b;
}
.buddhaHeart li {
	background-color: #671f1f;
}
.countTotal {
	display: flex;
	font-size: 20px;
	padding: 0 18px 0 18px;
	color: brown;
	font-weight: bold;
	justify-content: space-between;
	align-items: center;
}
div.countTotal > span {
	font-size: 25px;
}
.countFont {
	font-size: 14px;
	font-weight: normal;
}
.countLine {
	margin: 12px 18px;
	border: solid 1px #e7e7e7;
	display: block;
}
li {
	position: relative;
	display: flex;
	vertical-align: top;
	color: #fff;
	font-size: 17px;
	height: 56px;
	padding: 4px;
	align-items: center;
	list-style-type: none;
	margin-bottom: 1px;
	margin-top: 10px;
	border-radius: 8px;
	background-image: url(../../../images/listBGrepeat.png);
	font-weight: bold;
}

.imgDiv {
	width: 45px;
	height: 56px;
	margin-right: 10px;
}
.buddhaListImage {
	object-fit: cover;
	width: 100%;
	height: 100%;
}
li.scriptureList > span {
	position: absolute;
	right: 12px;
	height: 20px;
}

.contentBG {
	display: flex;
	font-size: 16px;
	background-color: #f0ecdb;
	border-radius: 8px;
	line-height: 1.7;
	font-weight: normal;
}
.contentImg {
	display: flex;
	position: relative;
	background-color: #000000;
	border-radius: 10px 0 0 10px;
	width: 33%;
	height: auto;
}
.listCount {
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	z-index: 2;
	font-size: 30px;
	width: 100%;
	font-weight: bold;
}
div.listCount > span {
	position: absolute;
	font-size: 10px;
	bottom: 16px;
	font-weight: normal;
}
.listBuddhaImg {
	object-fit: cover;
	border-radius: 10px 0 0 10px;
	width: 100%;
	height: 100%;
	position: absolute;
	opacity: 0.5;
}
.contentText {
	width: 67%;
	padding: 16px 16px 6px 16px;
	text-align: justify;
	text-justify: auto;
}
div.contentText > span {
	display: block;
	border-top: solid #e2d7c0 0.5px;
	margin-top: 15px;
}
.linkPlay {
	margin-top: 12px;
	margin-right: 4px;
	text-align: end;
}
.scriptureIntro {
	overflow: hidden;
	height: 0;
	transition: height ease 0.2s;
}

a {
	text-decoration: none;
}
.arrow {
	width: 20px;
	height: 20px;
	transition: ease 0.2s;
}
.arrowUp {
	transform: rotate(180deg);
	fill: aliceblue;
}
.playStart {
	width: 85px;
	height: 27px;
}
.gotoZen {
	display: flex;
	font-size: 16px;
	padding: 12px;
	color: #fff;
	font-weight: bold;
	justify-content: center;
	align-items: center;
	background: #508385;
	margin: 6px 17px 17px 17px;
	border-radius: 10px;
	box-shadow: 0px 4px 6px rgb(0 0 0 / 20%);
} */

.content {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	/* height: 100vh; */
	overflow: scroll;
}

.bannerTitle {
	width: 80%;
	margin-top: 40px;
	margin-bottom: 40px;
}
div.bannerTitle > img {
	width: 100%;
	margin-bottom: 12px;

}
.titleText {
	text-align: center;
	color: #e9ad00;
	font-size: 16;
}
div.banner > img {
	width: 100%;
	max-width: 720px;
	height: auto;
	background-color: transparent;
}

div.Bg > img{
	width: 100%;
	height: 100%;
	position: absolute;
	z-index: -1;
	right: 0;
}
.linkBtn {
	display: flex;
	justify-content: center;
	align-items: flex-start;
}
.Btn {
	border-radius: 8px;
	width: 54%;
	/* height: 100%; */
	overflow: hidden;
	margin-bottom: 8px;
	background-color: #050808;
	/* display: inline-block;
	margin: 0,auto; */
	
}
div.Btn > img{
	width: 100%;
	height: 100%;
}


