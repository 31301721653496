.lineFixedDiv {
	display: flex;
	justify-content: center;
}
.lineShareFixed {
	z-index: 9;
	position: fixed;
	width: 90%;
	max-width: 600px;
	bottom: 50px;
	color: #fff;
	font-weight: bold;
	display: flex;
	align-items: center;
	background-color: #07a749;
	border-radius: 12px;
	font-size: 18px;
	padding: 14px 16px;
	border: none;
	justify-content: center;
	box-shadow: 0px 2px 6px rgb(0 0 0 / 30%);
}
button.lineShareFixed > img {
	height: 30px;
	margin-right: 6px;
}
div.sharePageContent > div > span.decLeft {
	position: absolute;
	top: 0;
	left: 4px;
}
div.sharePageContent > div > span.decRight {
	position: absolute;
	top: 0;
	right: 4px;
}
div.sharePageContent > div > span.decLeftDown {
	position: absolute;
	bottom: 0;
	left: 4px;
}
div.sharePageContent > div > span.decRightDown {
	position: absolute;
	bottom: 4px;
	right: 4px;
}
.sharePageContent {
	text-align: center;
	/* position: absolute;
	width: 100%;
	left: 50%;
	transform: translate(-50%, -50%); */
}
div.sharePageContent > div {
	position: relative;
	font-weight: bold;
	font-size: 16px;
	color: #750606;
	padding: 18px 14px;
	margin: 18px;
	margin-top: -60px;
	border: solid 1px #bf9f5b;
	border-radius: 6px;
	background-color: #f3ecec;
	box-shadow: 0px 2px 6px rgb(0 0 0 / 30%);
}
div.sharePageContent > div > span {
	display: block;
	color: #212121;
	font-size: 14px;
	margin-top: 4px;
	font-weight: normal;
}
.sharePageIntro {
	position: relative;
	z-index: 3;
	display: flex;
	flex-flow: column;
	flex-direction: column;
	text-align: center;
	margin-bottom: 16px;
}
div.sharePageIntro > p > span {
	color: #750606;
	padding: 0 6px;
}
div.sharePageIntro > p {
	flex: 1;
}
div.shareBottomImg > img {
	width: 100%;
	position: fixed;
	bottom: 0;
}
div.shareSpace > img {
	position: relative;
	z-index: 2;
	width: 100%;
	margin-bottom: -4px;
}

div.sharePageIntro > div {
	color: #000;
	font-weight: bold;
	margin: 12px 0;
	font-size: 18px;
}
