div.blessBanner > img {
	width: 100%;
}
.blessList {
	font-size: 20px;
	font-weight: bold;
	color: black;
	padding: 0;
}

.secondTitle {
	font-size: 12px;
	margin: 0 0 4px 0;
	font-weight: lighter;
	color: #7d5212;
}
.arrowBless {
	position: absolute;
	right: 18px;
	width: 20px;
	height: 20px;
	transition: ease 0.2s;
}
.arrowUpBless {
	transform: rotate(180deg);
}
.blessImgDiv {
	width: 51px;
	height: 59px;
	margin-right: 10px;
}
.blessImage {
	object-fit: cover;
	width: 100%;
	height: 100%;
}
.blessContentIntro {
	overflow: hidden;
	height: 0;
	transition: height ease 0.4s;
}
.blessContentAuto {
	height: auto;
}
div.blessContent > span {
	background-color: #876f47;
	padding: 8px 0;
	text-align: center;
	display: block;
	margin-bottom: -16px;
	border-radius: 6px;
	line-height: 1;
	color: #fff;
	background-image: url(../../../images/listBGrepeat.png);
}
.blessIntro {
	margin: 6px 18px 0 18px;
	font-size: 17px;
	text-align: justify;
	text-justify: auto;
	line-height: 1.6;
	color: #212121;
}
.blessContent {
	color: #212121;
	font-size: 17px;
	font-weight: normal;
	text-align: justify;
	text-justify: auto;
	margin-bottom: 12px;
	line-height: 1.6;
}
.address {
	padding: 4px;
	color: #464141;
	font-size: 12px;
	border: solid 1px #e2e2e2;
	margin-top: 6px;
}
.blessListImg {
	width: 100%;
	border-radius: 6px;
}
.blessLine {
	border: solid 1px #f1f1f1;
	width: 100%;
	display: block;
	margin: 12px 0;
}
.blessUl {
	padding: 18px;
}
.enterNameDiv {
	display: flex;
	justify-content: center;
}
.enterName {
	width: 80%;
	position: fixed;
	bottom: 40px;
	padding: 12px;
	border-radius: 28px;
	border: solid 1px #9f1430;
	background-color: #a70121;
	color: #fff;
	font-weight: bold;
	font-size: 18px;
	box-shadow: 0px 2px 6px rgb(0 0 0 / 50%);
}
.ownHistory {
	margin-top: 25px;
	text-align: justify;
	text-justify: auto;
}
.ownHistoryBtn {
	display: flex;
	align-items: center;
	font-weight: bold;
	font-size: 17px;
	transition: ease 0.5s;
	color: brown;
	border-radius: 8px;
	border: solid 1px #e2e2e2;
	padding: 6px 0;
}
.ownHistoryBtnClick {
	transition: ease 0.5s;
	color: #212121;
}
.ownHistoryContent {
	/* border: solid 1px#BF9F5B; */
	color: #fff;
	overflow: hidden;
	height: 0;
	margin-top: 6px;
	transition: height ease 0.2s;
	background-color: #403935;
	border-radius: 8px;
	/* box-shadow: 0px 4px 8px rgb(0 0 0 / 20%); */
}
div.ownHistoryContent > div {
	padding: 16px;
	font-size: 15px;
	line-height: 1.6;
	font-weight: normal;
}
div.ownHistoryContent > div > span {
	color: #dabf84;
	font-weight: bolder;
	font-size: 16px;
}
.arrowHistory {
	width: 20px;
	height: 20px;
	transition: ease 0.2s;
	fill: brown;
}
.arrowUpHistory {
	transform: rotate(90deg);
	fill: #212121;
}
.bottomSpace {
	height: 10px;
	width: 100%;
}
