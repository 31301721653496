.navbar {
	position: sticky;
	display: flex;
	width: 100%;
	height: 44px;
	top: 0;
	left: 0;
	background-color: #630f10;
	align-items: center;
	justify-content: center;
	z-index: 900;
}
.menuIcon {
	position: absolute;
	height: 30px;
	/* top: 5px; */
	right: 16px;
	fill: azure;
}
.navLogo {
	width: 34px;
	height: 34px;
}
.navClose {
	height: 100%;
	width: 0%;
	position: fixed;
	z-index: 1;
	top: 0;
	left: 0;
	background-color: #f3ecec;
	transition: 0.2s ease;
	overflow-x: hidden;
	display: flex;
	flex-direction: column;
	z-index: 910;
}
.navCloseBtn {
	text-align: end;
	margin-top: 6px;
	margin-right: 12px;
	font-size: 24px;
}
.navOpen {
	width: 64%;
	z-index: 910;
}
div.navClose > a > p {
	padding: 12px;
	margin: 0;
	border-bottom: solid 1px #e2e2e2;
	font-size: 18px;
	color: #212121;
}
div.navClose > a {
	color: #212121;
}

.navOpacityOpen {
	position: fixed;
	background-color: #000;
	opacity: 0.6;
	width: 100%;
	height: 100%;
	transition: 0.2s ease;
}
.copyright {
	position: absolute;
	width: 100%;
	font-size: 11px;
	left: 0px;
	bottom: 0px;
	line-height: 1.6;
	color: antiquewhite;
	background-color: #474541;
}
div.copyright > div {
	margin: 20px 0 40px 14px;
}
.shareBtnNav {
	font-weight: bold;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 15px;
	margin: 12px;
	padding: 9px 0;
	margin-top: 24px;
	border-radius: 6px;
	border: none;
	color: #fff;
	background-color: #00ad48;
}
.addBtnNav {
	font-weight: bold;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 15px;
	margin: 12px;
	padding: 9px 0;
	/* margin-top: 24px; */
	border-radius: 6px;
	border: none;
	color: #fff;
	background-color: #811414;
}
button.addBtnNav > img {
	margin-right: 8px;
	height: 18px;
	width: 18px;
}

button.shareBtnNav > img {
	margin-right: 8px;
	height: 18px;
	width: 18px;
}
