.zenVideo {
	z-index: -1;
}
.videoIcon {
	position: absolute;
	left: 0;
	width: 100%;
	text-align: center;
	top: 28%;
	z-index: 2;
	/* transition: 1s ease; */
	opacity: 0;
}
.videoIconOpen {
	transition: 1s ease;
	opacity: 1;
}
div.videoIcon > img {
	width: 42px;
}

.changeZen {
	font-size: 15px;
	font-weight: bold;
	color: #fff;
	margin-top: 33px;
	margin-bottom: -8px;
	text-shadow: 0px 2px 6px rgb(0 0 0 / 70%);
}
div.videoIcon > span {
	display: inline-flex;
	width: 70px;
	border: solid 1px #fff;
}
.startZen {
	display: flex;
	justify-content: center;
}
.startZenClose {
	display: none;
}
div.startZen > div {
	color: #fff;
	font-size: 18px;
	font-weight: bold;
	position: fixed;
	width: 80px;
	display: flex;
	height: 80px;
	background-color: #811414;
	border-radius: 80px;
	justify-content: center;
	align-items: center;
	bottom: 50px;
	box-shadow: 0px 2px 6px rgb(0 0 0 / 70%);
	z-index: 4;
}
.zenStart {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 4;
}
.zenStartClose {
	display: none;
	/* height: 0;
	width: 100%;
	overflow: hidden; */
}
.zenFirstContent {
	text-align: center;
	width: 100%;
	padding: 28px;
	line-height: 2;
}
.musicIcon {
	fill: #fff;
	width: 36px;
}
.zenFirst {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}
.zenFirstText {
	letter-spacing: 1px;
	margin-top: 12px;
	color: #fff;
	width: 100%;
}
div.zenFirstText > div {
	font-size: 18px;
	font-weight: bold;
}
.zenModal {
	display: none;
	position: absolute;
	left: 0;
	color: #fff;
	width: 100%;
	height: 100%;
	z-index: 4;
}
.zenModalOpen {
	display: block;
}
.zenModalBG {
	position: absolute;
	top: 0;
	left: 0;
	background-color: #000;
	width: 100%;
	height: 100%;
	opacity: 0.7;
	z-index: -1;
}

.zenBtn1 {
	width: 60px;
	height: 60px;
	border-radius: 60px;
	background-image: linear-gradient(
		45deg,
		#ff9a9e 0%,
		#fad0c4 99%,
		#fad0c4 100%
	);
}
.zenBtn2 {
	width: 60px;
	height: 60px;
	border-radius: 60px;
	background-image: linear-gradient(120deg, #d4fc79 0%, #96e6a1 100%);
}
.zenBtn3 {
	width: 60px;
	height: 60px;
	border-radius: 60px;
	background-image: linear-gradient(120deg, #f6d365 0%, #fda085 100%);
}
.zenBtn4 {
	width: 60px;
	height: 60px;
	border-radius: 60px;
	background-image: linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%);
}
.zenBtn5 {
	width: 60px;
	height: 60px;
	border-radius: 60px;
	background-image: linear-gradient(135deg, #fdfcfb 0%, #e2d1c3 100%);
}
.zenBtn6 {
	width: 60px;
	height: 60px;
	border-radius: 60px;
	background-image: linear-gradient(to top, #cfd9df 0%, #e2ebf0 100%);
}
.zenBtn7 {
	width: 60px;
	height: 60px;
	border-radius: 60px;
	background-image: linear-gradient(to right, #ffecd2 0%, #fcb69f 100%);
}
.zenBtn8 {
	width: 60px;
	height: 60px;
	border-radius: 60px;
	background-image: linear-gradient(120deg, #a1c4fd 0%, #c2e9fb 100%);
}
.zenBtnUl {
	padding: 4px 27px;
	display: flex;
	justify-content: space-between;
	margin: 0;
	text-align: center;
}

.zenText {
	width: 60px;
	font-size: 16px;
}
.zenModalDiv {
	margin-top: 50%;
}
.zenTextUl {
	font-weight: 300;
	padding: 4px 27px;
	display: flex;
	justify-content: space-between;
	margin: 0;
	text-align: center;
	margin-bottom: 30px;
}
.zenStartBG {
	position: fixed;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: black;
	opacity: 0;
	z-index: 1;
	transition: 1s ease;
}
.zenStartBGOpen {
	opacity: 0.3;
}

.countDownDiv {
	display: flex;
	position: absolute;
	left: 0;
	top: 0;
	text-align: center;
	height: 100%;
	width: 100%;
	z-index: 9;
	justify-content: center;
	align-items: center;
}
.countDown {
	position: absolute;
	top: 80px;
	font-size: 14px;
	color: #fff;
	font-weight: lighter;
}
.zenTimeClose {
	display: none;
}
.zenTimeShow {
	display: block;
}
.zenClick {
	position: absolute;
	left: 0;
	width: 100%;
	bottom: 50px;
	color: #fff;
	font-size: 13px;
}
.breatheDiv {
	width: 100%;
	height: 100%;
	overflow: hidden;
}
.donL {
	position: absolute;
	left: 0;
	width: 50%;
	height: 100%;
	z-index: 1;
}
.donR {
	position: absolute;
	right: 0;
	width: 50%;
	height: 100%;
	z-index: 1;
}
.zenStartPop {
	position: absolute;
	z-index: 9;
	line-height: 1.7;
}
.zenStartPopClose {
	display: none;
}
div.zenStartPop > div {
	background: #fff;
	position: relative;
	padding: 24px;
	border-radius: 8px;
	margin: 20px;
}
.readyBtn {
	width: 90%;
	padding: 10px;
	margin-top: 10px;
	font-weight: bold;
	font-size: 16px;
	color: #fff;
	background-color: #811414;
	border: none;
	border-radius: 8px;
}
.laterBtn {
	font-size: 16px;
	border: none;
	background: none;
	margin-top: 16px;
	color: #811414;
	font-weight: bold;
}
.compleatHide {
	display: none;
}
.compleatShow {
	display: flex;
	position: absolute;
	justify-content: center;
	align-items: center;
	width: 100%;
	z-index: 2;
}
.textUnderline {
	display: inline-flex;
	width: 50%;
	border: solid 1px #25936d;
	margin-bottom: -2px;
}
.compleatPop {
	color: #fff;
	position: relative;
	width: 100%;
	height: 100%;
	padding: 8px 18px;
	margin: 20px;
	border-radius: 16px;
	background-image: linear-gradient(to top, #0ba360 0%, #3cba92 100%);
	box-shadow: 0px 4px 6px rgb(0 0 0 / 20%);
}
.compleatDate {
	font-size: 14px;
	opacity: 0.8;
}
div.compleatPop > div > div {
	display: flex;
	justify-content: space-around;
}
div.compleatPop > div > div > button {
	width: 45%;
	padding: 8px;
	margin: 24px 0 12px 0;
	font-weight: bold;
	font-size: 16px;
	color: #fff;
	background: none;
	border: solid 1px #fff;
	border-radius: 30px;
}
.compleatContent {
	font-size: 17px;
	line-height: 1.7;
	margin-top: 4px;
}
.zenCompleatTitle {
	font-size: 14px;
	margin-bottom: -10px;
}
p.zenCompleatTitle > span {
	font-weight: bold;
	margin: 0 4px;
}
.countClickHide {
	display: none;
}
.countClickShow {
	display: block;
	z-index: -1;
}
.countHistory {
	color: #000;
	font-size: 14px;
	border-radius: 12px;
	display: flex;
	justify-content: space-around;
	background-color: mediumaquamarine;
	margin-bottom: 40px;
	opacity: 0.9;
}
div.countHistory > p {
	margin: 10px 0;
}
.videoContainer {
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	visibility: visible;
	overflow: hidden;
}
div.videoContainer > video {
	height: 100%;
	width: 100%;
	object-fit: cover;
}
.zenLineShare {
	width: 100%;
	color: #fff;
	font-weight: bold;
	display: flex;
	align-items: center;
	background-color: #04c755;
	border-radius: 12px;
	font-size: 16px;
	padding: 6px 0;
	border: none;
	justify-content: center;
	box-shadow: 0px 5px 6px rgb(0 0 0 / 15%);
	margin-top: 18px;
}

button.zenLineShare > img {
	height: 26px;
	margin-right: 4px;
}
