.orderDiv {
	width: 100%;
}

.orderUl {
	font-weight: normal;
	height: 100%;
	margin: 0;
	z-index: 999;
}

.orderContent {
	font-size: 14px;
	margin: 18px;
	padding: 18px;
	background-color: #eee;
	border-radius: 6px;
	text-align: justify;
	text-justify: auto;
	box-shadow: 0px 2px 6px rgb(0 0 0 / 30%);
}
.nullValues {
	line-height: 1.6;
	font-size: 16px;
	position: absolute;
	top: 28%;
	margin: 18px;
	padding: 32px 18px;
	background-color: #eee;
	border-radius: 6px;
	text-align: center;
	text-justify: auto;
	box-shadow: 0px 2px 6px rgb(0 0 0 / 30%);
}
div.nullValues > span {
	font-weight: bold;
}

.goBuyPage {
	font-size: 16px;
	font-weight: bold;
	display: block;
	width: 100%;
	padding: 8px;
	border-radius: 8px;
	margin-top: 24px;
	border: none;
	background-color: #750606;
	color: #fff;
	font-weight: bold;
}
.orderDate {
	font-size: 18px;
	font-weight: bold;
}

.orderTitle {
	color: #750606;
	font-weight: bold;
	margin-bottom: 8px;
}

.orderLine {
	display: block;
	border: solid 1px #dfd4bd;
	margin: 12px 0px;
}

.payIntro {
	display: flex;
	background-color: #750606;
	padding: 10px;
	color: #fff;
	font-weight: bold;
	border-radius: 8px;
	margin-top: 24px;
	justify-content: space-between;
}
.payTransfer {
	padding: 10px;
	background-color: #e6ddd0;
	border-radius: 8px;
	margin-top: 12px;
	border-radius: 8px 8px 0 0;
	line-height: 1.6;
	color: #212121;
}
div.payInformation > p {
	margin: 4px;
}
div.payTransfer > span {
	color: #750606;
}

.payInformation {
	background-color: #ebe7e0;
	padding: 6px 10px 10px 10px;
	border-radius: 0 0 8px 8px;
}

.payNum {
	font-weight: bold;
	font-size: 16px;
	margin-top: 6px;
	margin-bottom: 6px;
}
.orderBottomSpace {
	height: 80px;
}

.checkOrder {
	width: 100%;
	height: 100%;
	top: 50%;
	left: 50%;
}
